import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useGetAllClaimStatusesQuery } from 'src/app/services/claim/claimStatusApi';

const ClaimStatuses = () => {
  const { t } = useTranslation('translations');
  const { data: claimStatuses, refetch } = useGetAllClaimStatusesQuery();

  return (
    <div style={{ display: 'grid' }}>
      <TableContainer>
        <div className="h-[40px] flex flex-row justify-between items-center dark:bg-dark">
          <h2 className="ml-5"> {t('ClaimStatuses')}</h2>
        </div>
        <Table style={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow className="dark:bg-dark">
              <TableCell>{t('Name')}</TableCell>
              <TableCell>{t('CreatedDate')}</TableCell>
              <TableCell>{t('Comment')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className=" overflow-y-auto">
            {claimStatuses &&
              claimStatuses?.data?.map((item) => (
                <TableRow key={item.id} className="bg-white hover:bg-grey-200 dark:bg-dark">
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {item.createdDate ? moment(item.createdDate).format('MM.DD.YYYY HH:mm:ss') : ''}
                  </TableCell>
                  <TableCell>{item.comment}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ClaimStatuses;
