const locale = {
  Applications: 'Modullar',
  EXAMPLE: 'Sahifa',
  HR: 'HR',
  ADMIN: 'ADMIN',
  Academy: 'Kurslar',
  Profile: 'Profil',
  Continue: 'Davom ettirish',
  Settings: 'Sozlamalar',
  Export: 'Eksport',
  CompanyNews: 'Kompaniya yangiliklari',
  Employees: 'Xodimlar',
  ProjectsManagement: 'Loyihalarni boshqarish',
  Projects: 'Loyihalar',
  Calendar: 'Kalendar',
  Chat: 'Chat',
  Mail: 'Pochta',
  SCRUMBOARD: 'SCRUMBOARD',
  Structure: 'Struktura',
  Blanks: 'Shakllar',
  Skud: 'Ish vaqtini hisobi',
  MySkud: 'Mening vaqtim',
  Departments: "Bo'limlar",
  Users: 'Foydalanuvchilar',
  Roles: 'Rollar',
  StaffTable: 'Xodimlar jadvali',
  SkudEmployees: "Bo'ysunuvchi xodimlari",
  SkudReport: 'Hisobot',
  Reservation: 'Bron qilish',
  MyCalendar: 'Mening kalendarim',
  References: "Ma'lumotnomalar",
  Support: 'Yordam',
  Presentations: 'Taqdimotlar',
  Main: 'Bosh sahifa',
  Basic: 'Asosiy',
  Work: 'Ish',
  Useful: 'Foydali',
  Administration: 'Boshqaruv',
  HumanResources: 'Xodimlar bilan ishlash',
  HelpCenter: 'Yordam markazi',
  Feedback: 'Qayta aloqa',
  Documents: 'Hujjatlar',
  MyUzAtom: 'Mening UzAtom',
  Map: 'Xarita',
  Maps: 'Xaritalar',
  DownloadApp: 'Ilovani yuklab olish',
  Logs: 'Loglar',
  ClaimExecutors: "So'rovlar ijrochilari",
  ClaimsProcessing: "So'rovlarni qayta ishlash",
  InternalDocuments: 'Ichki hujjatlar',
  VisitBooking: 'Uchrashuv qabuliga yozilish',
  ElectronMail: 'Elektron pochta',
};

export default locale;
