const locale = {
  Applications: 'Модули',
  HR: 'HR',
  ADMIN: 'АДМИНИСТРАТОР',
  EXAMPLE: 'Пример',
  Academy: 'Курсы',
  Profile: 'Профиль',
  Continue: 'Продолжить',
  Settings: 'Настройки',
  Export: 'Экпорт',
  CompanyNews: 'Новости компании',
  Employees: 'Сотрудники',
  Chat: 'Чат',
  Mail: 'Почта',
  ProjectsManagement: 'Управление проектами',
  Projects: 'Проекты',
  Contracts: 'Контракты',
  Structure: 'Орг. структура',
  Blanks: 'Бланки заявлений',
  Skud: 'Учет рабочего времени',
  SkudEmployees: 'Подчинённые',
  MySkud: 'Моё время',
  Departments: 'Департаменты',
  Users: 'Пользователи',
  Roles: 'Роли',
  StaffTable: 'Штатное расписание',
  References: 'Справочники',
  Companies: 'Компании',
  Positions: 'Должности',
  HelpDesk: 'Help Desk',
  SkudReport: 'Отчёт',
  Calendar: 'Календарь',
  ResourcesCalendar: 'Календарь ресурсов',
  MyCalendar: 'Мой календарь',
  Reservation: 'Бронирование',
  Support: 'Поддержка',
  Presentations: 'Презентации',
  Main: 'Главная',
  Basic: 'Основное',
  Work: 'Работа',
  Useful: 'Полезное',
  Administration: 'Администрирование',
  HumanResources: 'Работа с персоналом',
  HelpCenter: 'Центр помощи',
  Feedback: 'Обратная связь',
  Documents: 'Документы',
  MyUzAtom: 'Мой UzAtom',
  Map: 'Карта',
  Maps: 'Карты',
  DownloadApp: 'Скачать приложение',
  Logs: 'Логи',
  ClaimExecutors: 'Исполнители обращений',
  ClaimsProcessing: 'Обработка заявок',
  InternalDocuments: 'Внутренние документы',
  VisitBooking:'Запись на приём',
  ElectronMail: 'Электронная почта'
};

export default locale;
