import _ from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from 'src/app/services/ticket/projectsApi';
import { PROJECT_ROLE } from './vars';
import {
  useGetBoardListByProjectQuery,
  useGetPhaseListByProjectQuery,
} from 'src/app/services/ticketsApi';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { selectAllStaff } from 'app/store/staffSlice';

export const ProjectContext = createContext(null);

export const ProjectProvider = ({ children }) => {
  const navigate = useNavigate();
  const { projectId, view, taskId } = useParams();
  const [cardDialogIsOpen, setCardDialogIsOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [currentList, setCurrentList] = useState(null);
  const [members, setMembers] = useState([]);
  const [owners, setOwners] = useState(null);

  const staffList = useSelector(selectAllStaff);
  const user = useSelector(selectUser);
  const { data: project, refetch: refetchProject } = useGetProjectByIdQuery(
    { id: projectId },
    { skip: !projectId }
  );
  const { data: phases, refetch: refetchPhases } = useGetPhaseListByProjectQuery(
    { project: projectId },
    { skip: !projectId }
  );
  const { data: lists } = useGetBoardListByProjectQuery({ projectId }, { skip: !projectId });

  const myStaffId = user?.staffId;

  const isRole = (role) =>
    members?.some((member) => member.staffID === myStaffId && member.projectRole === role);
  const isOwner = isRole(PROJECT_ROLE.OWNER);
  const isContractor = isRole(PROJECT_ROLE.CONTRACT_RESPONSIBLE);
  const isObserver = isRole(PROJECT_ROLE.OBSERVER);

  const navigateToTask = (id = '') => navigate(`/apps/projects/${projectId}/${view}/${id}/edit`);

  const openCardDialog = (id) => {
    setCardDialogIsOpen(true);
    setCurrentCard(id);
  };

  const openNewCardDialog = () => {
    setCardDialogIsOpen(true);
    setCurrentCard(null);
  };

  const closeCardDialog = () => {
    setCardDialogIsOpen(false);
    setCurrentCard(null);
    navigate(`/apps/projects/${projectId}/${view}`);
  };

  useEffect(() => {
    if (project && staffList) {
      setMembers(
        project.projectMembers
          ?.map((member) => {
            const foundStaff = _.find(staffList, (staff) => staff.staffID === member.staffId);
            if (!foundStaff) return null;

            return {
              ...foundStaff,
              projectRole: member.role,
            };
          })
          .filter(Boolean)
      );
      setOwners(
        project.projectMembers
          ?.map((member) => {
            const foundStaff = _.find(
              staffList,
              (staff) => staff.staffID === member.staffId && member.role === 1
            );
            if (!foundStaff) return null;

            return {
              ...foundStaff,
              projectRole: member.role,
            };
          })
          .filter(Boolean)
      );
    }
  }, [project, staffList]);

  useEffect(() => {
    if (project && lists && currentCard) {
      setCurrentList(
        lists.find((list) => list.ticketList.find((card) => card.id === currentCard.id))
      );
    }
  }, [currentCard]);

  if (!project) return null;

  return (
    <ProjectContext.Provider
      value={{
        project,
        refetchProject,
        lists,
        cardDialogIsOpen,
        openCardDialog,
        openNewCardDialog,
        closeCardDialog,
        currentList,
        currentCard,
        setCurrentList,
        setCurrentCard,
        members,
        owners,
        phases,
        refetchPhases,
        myStaffId,
        isContractor,
        isOwner,
        isObserver,
        navigateToTask,
        projectId,
        view,
        taskId,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => useContext(ProjectContext);
