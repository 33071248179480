import { useEffect } from 'react';
import JwtService from 'src/app/auth/services/jwtService';
import { getUserDeviceInfo } from 'src/app/helpers/utils';
import { useLoginWithMyIdMutation } from 'src/app/services/accountApi';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useMessage from 'src/app/hooks/useMessage';

const { useSearchParams } = require('react-router-dom');

const SignInWithMyId = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [logInWithMyid, { isLoading }] = useLoginWithMyIdMutation();
  const { t } = useTranslation('translations');
  const { showMessage } = useMessage();

  const deviceInfo = getUserDeviceInfo();

  const reasonCode = searchParams.get('reason_code');

  useEffect(() => {
    const authCode = searchParams.get('code');
    const access_token = window.localStorage.getItem('jwt_access_token');
    if (authCode && !access_token) {
      logInWithMyid({
        authCode,
        deviceInfo,
        deviceType: 10345134,
      })
        .unwrap()
        .then((data) => {
          if (data.token) {
            JwtService.setSession(data.token);
            JwtService.setRefreshToken(data.refreshToken);
            JwtService.setRefreshTokenExpirationDate(data.refreshTokenExpiration);
            JwtService.handleAuthentication();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            showMessage({ message: message, variant: 'success' });
          }
          console.error(error);
        });
    }
  }, []);

  return (
    <div className="h-full flex justify-center items-center">
      <div className="w-[500px] max-w-[90%] py-[20px] px-[40px] rounded-6 flex flex-col justify-center items-center">
        <img
          alt="uzatom-logo"
          src="assets/images/logo/logo.svg"
          className="w-[150px] max-w-[80%] mb-20"
        />
        {isLoading && (
          <div className="text-[#004DA7] text-16">
            {t('GettingPermissionForEnter')}
            <LoadingOutlined className="ml-5" />
          </div>
        )}
        {reasonCode && (
          <div>
            <p className="mt-5">{t('MyIDAuthError')}</p>
            <a href="https://my.uzatom.uz">{t('BackToLoginPage')}</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignInWithMyId;
