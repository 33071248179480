import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';
import { DayPilot } from '@daypilot/daypilot-lite-react';

export const resourceApi = createApi({
  reducerPath: 'resourceApi',
  baseQuery: rtkBaseQuery('/resource'),
  tagTypes: ['Calendars', 'Resources', 'Events', 'Appointments'],
  endpoints: (builder) => ({
    // Calendars
    createCalendar: builder.mutation({
      query: (body) => ({
        url: '/Resource/CreateCalendar',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Calendars'],
    }),
    getCalendarList: builder.query({
      query: (id) => ({
        url: `/Resource/GetCalendarList?type=${id}`,
      }),
      providesTags: ['Calendars'],
    }),
    editCalendar: builder.mutation({
      query: (body) => ({
        url: '/Resource/EditCalendar',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Calendars'],
    }),
    deleteCalendar: builder.mutation({
      query: (id) => ({
        url: `/Resource/DeleteCalendar?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Calendars'],
    }),
    // Resources
    getResourceList: builder.mutation({
      query: (body) => ({
        url: '/Resource/GetResourceList',
        method: 'POST',
        body,
      }),
      providesTags: ['Resources'],
    }),
    createResource: builder.mutation({
      query: (body) => ({
        url: '/Resource/CreateResource',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Resources'],
    }),
    getResourceById: builder.query({
      query: (id) => `/Resource/GetResourceById/${id}`,
    }),
    editResource: builder.mutation({
      query: (body) => ({
        url: '/Resource/EditResource',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Resources'],
      // invalidatesTags: (result, error, { projectId }) => [{ type: 'Project', id: projectId }],
    }),
    deleteResource: builder.mutation({
      query: (id) => ({
        url: `/Resource/DeleteResource?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Resources'],
    }),

    // Events
    createEvent: builder.mutation({
      query: (body) => ({
        url: '/Resource/CreateEvent',
        method: 'POST',
        body,
      }),
    }),
    getEventList: builder.mutation({
      query: (body) => ({
        url: '/Resource/GetEventList',
        method: 'POST',
        body,
      }),
      providesTags: ['Events'],
    }),
    editEvent: builder.mutation({
      query: (body) => ({
        url: '/Resource/EditEvent',
        method: 'POST',
        body,
      }),
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `/Resource/DeleteEvent?id=${id}`,
        method: 'DELETE',
      }),
    }),
    addStaffToEvent: builder.mutation({
      query: (body) => ({
        url: '/Resource/AddStaffToEvent',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Events'],
    }),
    removeStaffFromEvent: builder.mutation({
      query: (body) => ({
        url: '/Resource/RemoveStaffFromEvent',
        method: 'POST',
        body,
      }),
    }),
    createAppointment: builder.mutation({
      query: (body) => ({
        url: '/Appointment/Create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Appointments'],
    }),
    editAppointment: builder.mutation({
      query: (body) => ({
        url: `Appointment/Edit/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Appointments'],
    }),
    removeAppointment: builder.mutation({
      query: ({ id }) => ({
        url: `Appointment/Remove/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Appointments'],
    }),
    getAppointment: builder.query({
      query: ({ id }) => `Appointment/Get?id=${id}`,
    }),
    getAllAppointents: builder.query({
      queryFn: async ({ from, to }, api, extraOptions, baseQuery) => {
        const state = api.getState();
        const allStaff = state.staffSlice.staff;
        try {
          const { data } = await baseQuery({
            url: `Appointment/GetAll?from=${from}&to=${to}`,
            method: 'GET',
          });
          return {
            data: data.map((r) => ({
              ...r,
              appointmentStaff: r.appointmentStaff.map((memberId) => {
                const foundEmpl = allStaff.find((staff) => staff.staffID == memberId);
                if (foundEmpl) {
                  return {
                    fullName: {
                      fio: `${foundEmpl.fullName_Surname} ${foundEmpl.fullName_Name} ${foundEmpl.fullName_Patronymic}`,
                      surname: foundEmpl.fullName_Surname,
                      name: foundEmpl.fullName_Name,
                      patronimyc: foundEmpl.fullName_Patronymic,
                    },
                    id: foundEmpl.staffID,
                    staffFileUrl: foundEmpl.staffFileUrl,
                  };
                } else {
                  return {
                    fullName: {
                      fio: '',
                    },
                    id: 0,
                    staffFileUrl: '',
                  };
                }
              }),
              text: r.name,
              resource: r.chiefId,
              start: new DayPilot.Date(r.start),
              end: new DayPilot.Date(r.end),
            })),
          };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ['Appointments'],
    }),
  }),
});

export const {
  useGetCalendarListQuery,
  useCreateCalendarMutation,
  useEditCalendarMutation,
  useDeleteCalendarMutation,
  useGetResourceListMutation,
  useGetResourceByIdQuery,
  useCreateResourceMutation,
  useEditResourceMutation,
  useDeleteResourceMutation,
  useCreateEventMutation,
  useGetEventListMutation,
  useEditEventMutation,
  useDeleteEventMutation,
  useAddStaffToEventMutation,
  useRemoveStaffFromEventMutation,
  useCreateAppointmentMutation,
  useEditAppointmentMutation,
  useRemoveAppointmentMutation,
  useGetAppointmentQuery,
  useGetAllAppointentsQuery,
} = resourceApi;
