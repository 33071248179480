import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: rtkBaseQuery('/auth/Account'),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: '/Login',
        method: 'POST',
        body,
      }),
    }),
    getUserProfile: builder.query({
      query: () => '/UserProfile',
    }),
    chooseMainRole: builder.mutation({
      query: (body) => ({
        url: '/ChooseMainRole',
        method: 'POST',
        body,
      }),
    }),
    changeUserPassword: builder.mutation({
      query: (body) => ({
        url: '/ChangeUserPassword',
        method: 'POST',
        body,
      }),
    }),
    refreshAccessToken: builder.mutation({
      query: () => {
        const refreshToken = window.localStorage.getItem('refresh_token');
        return {
          url: '/RefreshAccessToken',
          method: 'POST',
          body: { refreshToken },
        };
      },
    }),
    onSaveProfileImage: builder.mutation({
      query: (body) => ({
        url: '/onSaveProfileImage',
        method: 'POST',
        body,
      }),
    }),
    fetchUserList: builder.mutation({
      query: (body) => ({
        url: '/GetUserList',
        method: 'POST',
        body,
      }),
    }),
    getUserList: builder.query({
      query: (params) => ({
        url: '/GetUserList',
        method: 'GET',
        params,
      }),
    }),
    getRoles: builder.query({
      query: () => '/getRoles',
    }),
    registerOrUpdateUser: builder.mutation({
      query: (body) => ({
        url: '/RegisterOrUpdateUser',
        method: 'POST',
        body,
      }),
    }),
    updateUserStaffId: builder.mutation({
      query: (body) => ({
        url: '/UpdateUserStaffId',
        method: 'POST',
        body,
      }),
    }),
    updateUserStatus: builder.mutation({
      query: (body) => ({
        url: '/UpdateUserStatus',
        method: 'POST',
        body,
      }),
    }),
    updateUserTheme: builder.mutation({
      query: (body) => ({
        url: 'UpdateUserTheme',
        method: 'POST',
        body,
      }),
    }),
    lockOutUser: builder.mutation({
      query: (id) => ({
        url: `/LockOutUser?id=${id}`,
        method: 'GET',
      }),
    }),
    listADUsers: builder.mutation({
      query: () => ({
        url: '/ListADUsers',
        method: 'GET',
      }),
    }),
    listADDisabledUsers: builder.mutation({
      query: () => ({
        url: '/ListADDisabledUsers',
        method: 'GET',
      }),
    }),
    getExel: builder.mutation({
      query: () => ({
        url: '/GetExel',
        method: 'GET',
      }),
    }),
    getUserByStaffId: builder.mutation({
      query: (body) => ({
        url: '/GetUserByStaffId',
        method: 'POST',
        body,
      }),
    }),
    loginWithMyId: builder.mutation({
      query: (body) => ({
        url: 'LoginMyIdByQrCode',
        method: 'POST',
        body,
      }),
    }),
    getTopVisitRole: builder.query({
      query: () => 'GetTopVisitRole',
    }),
  }),
});

export const {
  useChangeUserPasswordMutation,
  useRefreshAccessTokenMutation,
  useChooseMainRoleMutation,
  useFetchUserListMutation,
  useGetUserListQuery,
  useGetExelMutation,
  useGetRolesQuery,
  useGetUserByStaffIdMutation,
  useGetUserProfileQuery,
  useListADDisabledUsersMutation,
  useListADUsersMutation,
  useLockOutUserMutation,
  useLoginMutation,
  useOnSaveProfileImageMutation,
  useRegisterOrUpdateUserMutation,
  useUpdateUserStaffIdMutation,
  useUpdateUserStatusMutation,
  useUpdateUserThemeMutation,
  useLoginWithMyIdMutation,
  useGetTopVisitRoleQuery,
} = accountApi;
