import { Download } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DownloadApp = () => {
  const { t } = useTranslation('translations');

  return (
    <div
      className="flex flex-col md:flex-row justify-center items-center h-full p-20 gap-52"
      style={{ backgroundImage: 'url(assets/images/apps/help-center/downloadapp.jpg)' }}
    >
      <div className="p-20 bg-white dark:bg-dark rounded-24 text-center">
        <div className="p-24 bg-white dark:bg-dark rounded-24 mb-20 text-center">
          <h1>{t('DownloadMobileApplication')} (Android)</h1>
        </div>
        <img
          className="w-[240px] h-[240px] md:w-[480px] md:h-[480px] mx-auto"
          alt="app-download-qrcode"
          src="assets/images/apps/help-center/qrcode_Android.svg"
        />
        <Button
          variant="contained"
          color="info"
          startIcon={<Download />}
          href="https://my-api.uzatom.uz/Mobile/MyUzAtom.apk"
          className="dark:text-white mt-20"
        >
          {t('Download') + ' (Android)'}
        </Button>
      </div>
    </div>
  );
};

export default DownloadApp;
