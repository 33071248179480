import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FileUpload from '@fuse/core/FileUpload';
import handleError from '@fuse/core/errorHandler';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Close } from '@mui/icons-material';
import { Button, IconButton, Input, TextareaAutosize, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEditArticleMutation } from 'src/app/services/articlesApi';
import WYSIWYGEditor from 'app/shared-components/WYSIWYGEditor';
import { EditorState } from 'draft-js';
import MarkdownEditor from '@uiw/react-markdown-editor';

const NewsEdit = ({ post, onOpen, onClose, refetchList, onEdit }) => {
  const { t } = useTranslation('translations');
  const [editArticle] = useEditArticleMutation();
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...post,
      articleFileIds: [],
    },
  });

  const onSubmit = (formValues) => {
    formValues.articleFileIds =
      formValues.articleFileIds && formValues.articleFileIds.length > 0
        ? formValues.articleFileIds.map((media) => media.id)
        : post.fileList.map((file) => file.id);

    editArticle(formValues)
      .unwrap()
      .then((response) => {
        handleClose();
        showMessage({
          message: t('NewsSuccessfullyEdited'),
          autoHideDuration: 3000,
          variant: 'success',
        });
        onEdit();
        refetchList();
      })
      .catch(handleError);
  };

  const handleClose = (event) => {
    onClose();
    reset({
      ...post,
      articleFileIds: [],
    });
  };

  useEffect(() => {
    reset({
      ...post,
      articleFileIds: [],
    });
  }, [post]);

  return (
    <Dialog
      open={onOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          minWidth: 700,
          minHeight: 300,
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="relative">
        <IconButton onClick={handleClose} className="absolute top-0 right-0">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography component="p" className="mb-20" variant="h6">
            {t('EditPost')}
          </Typography>
          <Controller
            name="title"
            control={control}
            className="mb-20"
            render={({ field }) => (
              <Input
                classes={{ root: 'text-18 w-full' }}
                placeholder={t('Heading')}
                margin="none"
                disableUnderline
                {...field}
              />
            )}
          />
          {/* <Controller
            name="text"
            className="mb-20"
            control={control}
            render={({ field }) => (
              <TextareaAutosize
                className="py-8 w-full h-160 dark:bg-dark"
                classes={{ root: 'text-14' }}
                placeholder={t('NewsText')}
                margin="none"
                {...field}
              />
            )}
          /> */}
          {/* <Controller
            name="text"
            render={({ field }) => <WYSIWYGEditor className="my-16" {...field} />}
            control={control}
          /> */}

          {/* <Root className={clsx('rounded-4 border-1 overflow-hidden w-full')}>
            <Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
          </Root> */}
          {/* <MarkdownEditor
            minHeight="200px"
            value={markdown}
            onChange={(value, viewUpdate) => setMarkdown(value)}
          /> */}
          <Controller
            name="text"
            render={({ field }) => (
              <MarkdownEditor
                minHeight="200px"
                {...field}
                toolbars={[
                  'bold',
                  'italic',
                  'header',
                  'strike',
                  'underline',
                  'quote',
                  'oilst',
                  'ulist',
                  'link',
                ]}
              />
            )}
            control={control}
          />
          <FileUpload
            name="articleFileIds"
            control={control}
            type={6}
            multiple
            // imagePreview
          />
          <div className="flex items-stretch justify-end gap-10">
            <Button variant="contained" className="dark:bg-lightblack" onClick={handleClose}>
              {t('Cancel')}
            </Button>
            <Button variant="contained" color="success" className="dark:text-white" type="submit">
              {t('Edit')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default NewsEdit;
