import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const VisitBookingCalendar = lazy(() => import('./VisitBookingCalendar'));

const VisitBookingAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/visit-booking',
      element: <VisitBookingCalendar />,
    },
  ],
};

export default VisitBookingAppConfig;
