import moment from 'moment';
import NewsMedia from './NewsMedia';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewsEdit from './NewsEdit';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { useSelector } from 'react-redux';
import { useUser } from 'src/app/hooks/useUser';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import './NewItem.css';

const NewsItem = ({ post, transition, onDelete, refetchList, onEdit }) => {
  const { t } = useTranslation('translations');
  const [anchorEl, setAnchorEl] = useState();
  const [resizeViewport, setResizeViewport] = useState(window.innerWidth);
  const [openNews, setOpenNews] = useState(false);
  const [hover, setHover] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const { isAdmin, isTop, isPR } = useUser();

  useEffect(() => {
    window.addEventListener('resize', function () {
      setResizeViewport(window.innerWidth);
    });
  }, [resizeViewport]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteArticle = (event) => {
    event.stopPropagation();
    onDelete(post);
  };

  const handleOpenNews = () => {
    setOpenNews(true);
  };

  const handleCloseNews = () => {
    setOpenNews(false);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleEditArticle = (event) => {
    event.stopPropagation();
    setOpenEditDialog(true);
  };

  return (
    <>
      <Card
        component={motion.div}
        variants={transition}
        key={post.id}
        className={`mb-16 shadow-2 hover:cursor-pointer`}
        sx={{ position: 'relative', minHeight: '160px' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOpenNews}
      >
        <div className="p-10">
          <CardHeader
            className="absolute right-0 top-0"
            avatar={null}
            action={
              <>
                {(isAdmin || isTop || isPR) && (
                  <IconButton aria-label="more" size="large" onClick={handleClick} className="p-0">
                    <FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
                  </IconButton>
                )}
                <Menu
                  id="simple-menu"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={handleClose}
                >
                  <div className="flex flex-col">
                    <MenuItem onClick={handleDeleteArticle} className="flex justify-center">
                      {t('Delete')}
                    </MenuItem>
                    <MenuItem onClick={handleEditArticle} className="flex justify-center">
                      {t('Edit')}
                    </MenuItem>
                  </div>
                </Menu>
              </>
            }
          />
          <div className="flex flex-col md:flex-row gap-16">
            <Box className={`basis-1/4 rounded-8 overflow-hidden ${hover ? 'perspective' : ''}`}>
              <NewsMedia
                media={post.fileList}
                className={`transition-transform duration-500 ${hover ? 'scale-in-img' : ''}`}
              />
            </Box>
            <div className="basis-4/5 flex flex-col justify-between">
              <CardContent className="flex flex-col p-0">
                {post.title && (
                  <Typography
                    variant="h6"
                    sx={{ cursor: 'pointer' }}
                    className={`
                        text-grey-800
                        dark:text-white
                        font-inter 
                        font-600
                        pt-14
                        pr-28
                        text-xl
                        hover:underline
                        transition 
                        duration-300
                        ease-in-out
                        ${hover ? 'text-blue-700' : ''}`}
                  >
                    {post.title}
                  </Typography>
                )}

                {post.text && (
                  <div className="text-grey-700 dark:text-white line-clamp-3 md:line-clamp-4 lg:line-clamp-5 font-inter font-500 pt-12 leading-relaxed cursor-pointer">
                    <MarkdownEditor.Markdown
                      source={post.text}
                      style={{
                        backgroundColor: 'transparent',
                        color: isDarkMode ? '#cdcdcd' : '#444444',
                      }}
                    />
                  </div>
                )}
              </CardContent>
              <div className="mt-10">
                <Typography className="text-11 text-darkgrey">
                  {moment(post.createdDate).format('DD.MM.YYYY HH:mm')}
                </Typography>
              </div>
            </div>
          </div>
        </div>

        {false && (
          <Box
            className="card-footer flex flex-col px-32 py-24 border-t-1"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? lighten(theme.palette.background.default, 0.4)
                  : lighten(theme.palette.background.default, 0.02),
            }}
          >
            <div className="flex flex-auto -mx-4">
              <Avatar className="mx-4" src="assets/images/avatars/profile.jpg" />
              <div className="flex flex-col flex-1 mx-4 items-end">
                <Paper className="w-full mb-16 shadow-0 border-1  overflow-hidden">
                  <Input
                    className="p-12 w-full"
                    classes={{ root: 'text-13' }}
                    placeholder={t('AddComment')}
                    multiline
                    rows="6"
                    margin="none"
                    disableUnderline
                  />
                </Paper>
                <div>
                  <Button variant="contained" color="secondary" size="small">
                    {t('PostComment')}
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        )}
      </Card>
      <NewsEdit
        onOpen={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        post={post}
        refetchList={refetchList}
        onEdit={onEdit}
      />
      <Dialog
        sx={{
          width: resizeViewport >= 1300 ? '50%' : resizeViewport >= 750 ? '75%' : '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        open={openNews}
        onClose={handleCloseNews}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{post.title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseNews}
          sx={{
            position: 'absolute',
            right: 2,
            top: 2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {post && post.fileList?.length > 0 && (
            <div className="rounded-lg overflow-hidden font-inter font-900">
              <NewsMedia media={post.fileList} />
            </div>
          )}
          <DialogContentText id="alert-dialog-description" className="mt-16 font-inter">
            <MarkdownEditor.Markdown
              source={post.text}
              style={{
                backgroundColor: 'transparent',
                color: isDarkMode ? '#cdcdcd' : '#444444',
              }}
            />
          </DialogContentText>
          <div className="mt-14">
            <p className="font-inter text-12 text-darkgrey">
              {moment(post.createdDate).format('DD.MM.YYYY HH:mm')}
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewsItem;
