import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';

export const useUser = () => {
  const user = useSelector(selectUser);

  const isAdmin = user?.roles?.includes('Администратор');
  const isTop = user?.roles?.includes('Топ');
  const isHR = user?.roles?.includes('Кадры');
  const isPR = user?.roles?.includes('PR');
  const isTopVisit = user?.roles?.includes('Top_Visit');
  const isTopVisitAssistant = user?.roles?.includes('Top_Visit_Assistant');

  return {
    user,
    isAdmin,
    isTop,
    isHR,
    isPR,
    isTopVisit,
    isTopVisitAssistant,
  };
};
